:root {
  --color-purple: #753bbd;
  --color-deep-blue: #2e4491;
  --color-mid-blue: #0054a7;
  --color-baby-blue: #f0effa;
  --color-soft-blue: #babfdf;
  --color-sky: #1373d1;
  --color-sky-2: #1776f2;
  --color-indigo: #44498d;
  --color-deep-red: #de2f28;
  --color-salsa: #ee3b34;
  --color-grass: #019f3e;
  --color-emerald: #10ac84;
  --color-cheddar: #ffaa01;
  --color-gold: #e2b40d;
  --color-cement: #a1aeb7;
  --color-concrete: #f6f6f6;
  --color-whitesmoke: #f5f5f5;
  --color-ash: #e2e7ee;
  --color-night: #34384b;
  --color-blush: #fdecea;
  --color-white: #ffffff;
  --color-blue: #1373d1;
  --color-desertstorm: #f3f2f1;
  --color-gray: #a9acc6;
  --color-prussian-blue: #014171;
  --color-navy-blue: #363e50;
  --color-light-yellow: #ffefac;
  --color-light-gold: #fadf4a;
  --color-teal: #00aec9;
  --color-cyan: #02b2b8;
  --color-grey-chateau: #95a2aa;
}
