.filter {
  display: flex;
  align-items: center;
}

.fieldContent {
  width: 250px;
}

.search {
  width: 800px;
  margin-left: 10px;
}

.downloadButton {
  justify-content: flex-end;
  display: flex;
}
